<template>
    <div>
        <v-row>
            <v-col cols="12" md="3">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-light">Nombre de SMS disponible : </div>
                    </div>
                    <div class="card-body text-center">
                            <div class="display-1 font-weight-bold m-0 p-0">{{solde_sms}}</div>
                    </div>
                </div>
            </v-col>
                <v-col cols="12" md="3">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-light">Nombre de campagne effectuer:</div>
                    </div>
                    <div class="card-body text-center">
                        <div class="display-1 font-weight-bold m-0 p-0"> {{solde_camp}} </div>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="text-right" >
                <v-btn color="primary" class="mr-2" style="border-radius:0px" @click="showDialogValidate()" v-if="form.contact.length > 0 && form.message != null  && form.message != '' && form.title != null  && form.title != '' ">
                    Envoyer <i class="fa-solid fa-paper-plane ml-2"></i> 
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <!-- Left pannel -->
            <v-col cols="12" md="8">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-bold">Formulaire d'envoie</div>
                    </div>
                    <div class="card-body">
                        <v-row class="mr-2">
                            <v-col cols="12" md="12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Titre de campagne</label>
                                    <input type="email" class="form-control b-0 mt-1" v-model="form.title">
                                    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                                </div>
                                <!-- <div class="row">
                                    <el-input placeholder="..." v-model="form.title">
                                        <template slot="prepend">Titre de campagne : </template>
                                    </el-input>
                                </div>                                 -->
                            </v-col>
                        </v-row>
                        <!-- <v-row class="mr-2">
                            <v-col cols="12" md="12">
                                <div class="row m-2">
                                    <div class="col-3 subtitle-1 font-weight-light">Expediteur :</div>
                                    <div class="col margin-bottom">
                                            <div  style="width:100%">
                                                <ui-select style="border:1px"
                                                    :options="expediteurOption"
                                                    v-model="expediteurSelect"
                                                ></ui-select>
                                            </div>
                                        </div>
                                </div>                                
                            </v-col>
                        </v-row> -->
                        <v-row class="mr-2 mb-2">
                            <v-col cols="12" md="12">
                                <div class="row">
                                    <div class="col-3 subtitle-1 font-weight-light">Choisir le model</div>
                                    <div class="col margin-bottom">
                                            <div  style="width:100%">
                                                <ui-select
                                                    :options="modelOption"
                                                    v-model="modelSelect"
                                                    @change="ChanModel()"
                                                ></ui-select>
                                            </div>
                                        </div>
                                </div>                                
                            </v-col>
                        </v-row> 
                        <v-row>
                            <v-col cols="12" md="12" class="mt-0">
                                <el-input type="textarea" placeholder="Saisir le message" :autosize="{ minRows: 10, maxRows: 12 }" v-model="form.message" maxlength="200" style="border-radius:0px" show-word-limit></el-input>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>

            <!-- right pannel -->
            <v-col cols="12" md="4">
                <div class="card" style="border-radius:0px;">
                    <div class="card-header">
                        <div class="subtitle-1 font-weight-bold">Details d'envoie</div>
                    </div>
                    <div class="card-body text-left">
                        <v-row>
                                <v-col cols="12" md="12">
                                <div class="card" style="border-radius:0px;">
                                    <div class="card-header text-center">
                                        <div v-if="form.contact.length >0" class="subtitle-1 font-weight-light"><b> Total de contact : {{form.contact.length}} </b></div>
                                        <div v-else class="subtitle-1 font-weight-light">Aucun contact  </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12">
                                <div class="subtitle-1 font-weight-light">Choix du/des destinataire(s) </div>
                            </v-col>
                            <v-col cols="12" md="12">
                                <el-radio-group v-model="destinataireType">
                                    <el-radio-button label="Clients"></el-radio-button>
                                    <el-radio-button label="Groupe de clients"></el-radio-button>
                                    <el-radio-button label="Manuelle"></el-radio-button>
                                </el-radio-group>
                            </v-col>
                        </v-row>
                        <el-divider></el-divider>
                        <v-row>
                            <v-col cols="12" md="12" v-if="destinataireType =='Clients'">
                                <label class="mb-2"><b> Choisir les utilisateurs </b></label>
                                <div class="data-picker" style="width:100%">
                                    <ui-select
                                        :options="userOption"
                                        has-search
                                        multiple
                                        v-model="userSelect"
                                    ></ui-select>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" v-else-if="destinataireType =='Manuelle'">
                                <label class="mb-2"><b> Saisir les numéros </b></label>
                                <small id="emailHelp" class="form-text text-muted"> (Utiliser une virgule après chaque numéro)</small>
                                <el-input type="textarea" placeholder="658974751,678987453,..." :autosize="{ minRows: 10, maxRows: 12 }" v-model="contactManuelle" style="border-radius:0px"></el-input>

                            </v-col>
                            <v-col cols="12" md="12" v-else>
                                <label class="mb-2"><b> Choisir le groupe </b></label>
                                <div class="data-picker" style="width:100%">
                                    <ui-select
                                        :options="groupeOption"
                                        v-model="groupeSelect"
                                    ></ui-select>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    
</template>
<script>

    import axios from 'axios';
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';

    export default {

        data() {
            return {

                solde_sms : null,
                solde_camp: null,

                expediteurOption : [
                    {label: 'CERVO', value: 1},
                    {label: 'INFOS', value: 2}
                ],

                modelOption: [
                ],

                userOption : [
                ],

                groupeOption : [
                ],

                expediteurSelect: '',
                modelSelect : '',
                userSelect: [],
                groupeSelect: '',
                contactManuelle: '',

                form:{
                    title : '',
                    message: '',
                    groupe_id: null,
                    type: null,
                    contact: []
                },

                destinataireType: 'Clients',
            };  
        },

        created() {
            this.loadCount();
            this.loadUsers();
        },

        methods: {

            async loadCount() {
                NProgress.start();
                try {
                
                    const response = await axios.get('v1/xperf/communication/sms/getSmsCount');
                    this.solde_sms = response.data.solde;
                    this.solde_camp = response.data.count_camp;

                } 
                catch (error) {
                     this.$notify({
                        type: 'error',
                        title: "Echec de recuperation du solde de sms et de campagnes"
                    });
                }
                NProgress.done();
            },

            async loadUsers(){
                NProgress.start();
                try {
                
                    const response = await axios.get('v1/xperf/communication/sms/client/getSmsConfig');
                    this.userOption = response.data.customers;
                    this.groupeOption = response.data.groups;
                    this.modelOption = response.data.models;
                } 
                catch (error) {
                     this.$notify({
                        type: 'error',
                        title: "Echec de recuperation du solde de sms et de campagnes"
                    });
                }
                NProgress.done();
            },

            ChanModel(){
                this.form.message = this.modelSelect.value;
            },

            showDialogValidate(){

                this.$swal({
                title: 'Confirmer ?',
                text: "Vous êtes sur le point d'effecuer l'envoie",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#007FFF',
                cancelButtonColor: '#EE9D06',
                confirmButtonText: 'Oui'
                }).then((result) => {
                if (result.value) {
                    this.sendMessage();
                }
                });
            },

            async sendMessage(){  

                console.log('form', this.form);

                try {
                    await axios.post('v1/xperf/communication/sms/client/send', this.form);

                    this.loadCount();
                    
                    this.$swal(
                            'Enregistrer !',
                            'Envoie effectuer avec succès',
                            'success'
                    );
                } 
                catch (error) {

                    this.$swal(
                            'Echec !',
                            "Echec de l'envoie",
                            'error'
                    );
                }


            },

            checkNumber(lists){
                
                let array_out = [];

                lists.forEach(element => {

                    if(!array_out.includes(element) && element != null && element != '') {
                        
                        element = element.toString();

                        if (element.length == 9) {
                            array_out.push(element);
                        }
                    }
                });

                return array_out;   
            },

        },

        watch: {

            userSelect : function (params) {

                let user = [];
                this.userSelect.forEach(element => {
                    user.push(element.value);
                });

                this.form.contact =  this.checkNumber(user);
                this.form.type = 2;

            },

            groupeSelect: function () {
                console.log(this.groupeSelect.id);
                this.form.contact = this.checkNumber(this.groupeSelect.value);
                this.form.groupe_id = this.groupeSelect.id;
                this.form.type = 1;
            },

            contactManuelle: function () {
                let user = this.contactManuelle.split(',');
                this.form.contact = this.checkNumber(user);
                this.form.type = 3;

            }

        }
        
    }
</script>